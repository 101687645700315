import { Button, Form, Input, notification } from "antd";
import mainLogo from "../../assets/mainLogo.svg";
import "../Login.css";
import { useState } from "react";
import axios from "axios";
import { CheckCircleFilled, DislikeOutlined } from "@ant-design/icons";

const ForgotPasswordOtpComponent = ({
  setForgotCard,
  setOtpSate,
  userNameOTP,
  arg,
}) => {
  const onFinishOTP = (values) => {
    if (values?.newPassword === values?.conformPassword) {
      const headers = {
        contentType: "application/json",
      };

      axios
        .post(
          `https://api.edpedia.co/v1.0/ulp/api/auth/resetPassword?userName=${userNameOTP}&enteredOTP=${values?.enteredOTP}&newPassword=${values?.newPassword}`,
          headers
        )
        .then((response) => {
          if (response.status === 200) {
            notification.success({
              message: " Successful",
              description: "Password Changed Successfully.",
              placement: "bottomRight",
              icon: <CheckCircleFilled style={{ color: "green" }} />,
            });
            setForgotCard(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            const errorMessage = error.response.data.message;
            notification.error({
              message: ` ${errorMessage}`,
              placement: "bottomRight",
              icon: <DislikeOutlined style={{ color: "red" }} />,
            });
          } else {
            notification.error({
              message: "An error occurred, please try again later",
              placement: "bottomRight",
              icon: <DislikeOutlined style={{ color: "red" }} />,
            });
          }
        });
    } else {
      notification.error({
        message: "Password Not Matched",
        placement: "bottomRight",
        icon: <DislikeOutlined style={{ color: "red" }} />,
      });
    }
  };
  return (
    <>
      <div className="secondMain">
        <div className="second_container ">
          <img src={mainLogo} alt="Logo" className="LogoMain" />
          <div>
            <h4 className="headingtext">{arg}</h4>
            <div className="formContainer formContainerfor2">
              <Form
                name="basic"
                labelCol={{
                  span: 10,
                }}
                wrapperCol={{
                  span: 18,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinishOTP}
                autoComplete="off"
              >
                <Form.Item
                  name="enteredOTP"
                  rules={[
                    {
                      required: true,
                      message: "* Required OTP",
                    },
                  ]}
                >
                  <Input placeholder="OTP" className="inputCssPassword" />
                </Form.Item>
                <Form.Item
                  name="newPassword"
                  rules={[
                    {
                      required: true,
                      message: "* Required New Password",
                    },
                  ]}
                >
                  <Input
                    type="password"
                    placeholder="New Password"
                    className="inputCssPassword"
                  />
                </Form.Item>
                <Form.Item
                  name="conformPassword"
                  rules={[
                    {
                      required: true,
                      message: "* Required Conform Password",
                    },
                  ]}
                >
                  <Input
                    type="password"
                    placeholder="Conform Password"
                    className="inputCssPassword"
                  />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 20,
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      backgroundColor: "white",
                      color: "#172b4d",
                      borderRadius: 20,
                      marginTop: 10,
                      width: 100,
                      height: 40,
                      marginRight: 30,
                    }}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordOtpComponent;
