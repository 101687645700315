// config.js

let instAdminUrl;
let staffUrl;
let studentUrl;
let libraryUrl;
let transportUrl;
let superAdminUrl;

if (process.env.NODE_ENV === 'production') {
  // Production environment variables
  instAdminUrl = process.env.REACT_APP_INST_ADMIN_URL
  staffUrl = process.env.REACT_APP_STAFF_URL
  studentUrl = process.env.REACT_APP_STUDENT_URL
  libraryUrl = process.env.REACT_APP_LIBRARY_URL
  transportUrl = process.env.REACT_APP_TRANSPORT_URL
  superAdminUrl=process.env.REACT_APP_SUPERADMIN_URL
} else {
  // Development environment variables
  instAdminUrl = process.env.REACT_APP_INST_ADMIN_URL
  staffUrl = process.env.REACT_APP_STAFF_URL
  studentUrl = process.env.REACT_APP_STUDENT_URL
  libraryUrl = process.env.REACT_APP_LIBRARY_URL
  transportUrl = process.env.REACT_APP_TRANSPORT_URL
  superAdminUrl=process.env.REACT_APP_SUPERADMIN_URL
}

const config = {
  instAdminUrl,
  staffUrl,
  studentUrl,
  libraryUrl,
  transportUrl,
  superAdminUrl
};

export default config;
