
import React, { useState } from "react";
import { Button, Checkbox, Form, Input, notification } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import config from "../../Config";
import "./Login.css";
import {
  DislikeOutlined,
  LikeTwoTone,
  CheckCircleOutlined,
} from "@ant-design/icons";
import lock from "../assets/lock.svg";
import mainLogo from "../assets/mainLogo.svg";
import ForgotComponent from "./ForgotComponent/ForgotComponent";

const Login = () => {
  const [showError, setShowError] = useState(false);
  const [loginInfo, setLoginInfo] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [forgotCard, setForgotCard] = useState(false);

  const [forgotPasswordState, setForgotPasswordState] = useState(false);
  const navigate = useNavigate();

  const onFinish = (values) => {
    const data = {
      username: values.username,
      password: values.password,
    };

    const headers = {
      contentType: "application/json",
    };

    axios
      .post("https://api.edpedia.co/v1.0/ulp/api/auth/login", data, headers)
      .then((response) => {
        // const pushingStudentRole = response.data.roles.push("STAFF");
        // console.log(pushingStudentRole);

        // const roles = response.data.roles;
        // console.log("roles", roles);

        // const rolesLength = response.data.roles.length;
        // console.log(("rolee", rolesLength));

        if (response.status === 200) {
          setLoginInfo(response.data);
          // notification.success({
          //   message: "Login Successful",
          //   description: "You have successfully logged in!",
          //   placement: "bottomRight",
          //   icon: <CheckCircleFilled style={{ color: "green" }} />,
          // });
          const accessToken = response.data.accessToken;
          // Cookies.set("jwt_Token", accessToken, { expires: 1 });

          if (response?.data?.roles?.length > 1 && imageLoaded) {
            const newUrl = "/home";
            window.history.replaceState({}, document.title, newUrl);
            navigate("/home", { state: response.data, replace: true });
          } else if (response.data.roles.includes("INSTITUTE_ADMIN")) {
            const newUrl = `${config.instAdminUrl}?accessToken=${accessToken}&branchCode=${response?.data?.branchCode}&userId=${response?.data?.entityId}&entity=${response?.data?.entity}`;
            window.location.replace(newUrl);
          } else if (response.data.roles.includes("STAFF")) {
            const newUrl = `${config.staffUrl}?accessToken=${accessToken}&branchCode=${response?.data?.branchCode}&userId=${response?.data?.entityId}&entity=${response?.data?.entity}`;
            window.location.replace(newUrl);
          } else if (response.data.roles.includes("STUDENT")) {
            const newUrl = `${config.studentUrl}?accessToken=${accessToken}&branchCode=${response?.data?.branchCode}&userId=${response?.data?.entityId}&entity=${response?.data?.entity}`;
            window.location.replace(newUrl);
          } else if (response.data.roles.includes("PARENT")) {
            const newUrl = `${config.studentUrl}?accessToken=${accessToken}&branchCode=${response?.data?.branchCode}&userId=${response?.data?.entityId}&entity=${response?.data?.entity}&userName=${values.username}`;
            window.location.replace(newUrl);
          } else if (response.data.roles.includes("LIBRARIAN")) {
            const newUrl = `${config.libraryUrl}?accessToken=${accessToken}&branchCode=${response?.data?.branchCode}&userId=${response?.data?.entityId}&entity=${response?.data?.entity}`;
            window.location.replace(newUrl);
          } else if (response.data.roles.includes("SUPER_ADMIN")) {
            const newUrl = `${config.superAdminUrl}?accessToken=${accessToken}&branchCode=${response?.data?.branchCode}&userId=${response?.data?.entityId}&entity=${response?.data?.entity}`;
            window.location.replace(newUrl);
          }
          else {
            const newUrl = `${config.instAdminUrl}?accessToken=${accessToken}&branchCode=${response?.data?.branchCode}&userId=${response?.data?.entityId}&entity=${response?.data?.entity}`;
            window.location.replace(newUrl);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          const errorMessage = error.response.data;
          notification.error({
            message: ` ${errorMessage}`,
            placement: "bottomRight",
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
        } else {
          notification.error({
            message: "An error occurred while loging, please try again later",
            placement: "bottomRight",
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
        }
        setShowError({ showError: true });
      });

    // .catch((error) => {
    //   notification.error({
    //     message: `${error}`,
    //     placement: "bottomRight",
    //     icon: <DislikeOutlined style={{ color: "red" }} />,
    //   });
    //   setShowError({ showError: true });
    //   if (error.response.status !== 200) {
    //     setShowError({ showError: true });
    //   }
    // });
  };

  const onFinishFailed = (errorInfo) => {
    // notification.error({
    //   message: `${errorInfo?.message}`,
    //   placement: "bottomRight",
    //   icon: <DislikeOutlined style={{ color: "red" }} />,
    // });
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const ForgotPasswordFunc = (arg) => {
    setForgotPasswordState(arg);
    setForgotCard(true);
  };

  return (
    <>
      <div className={`main_container ${imageLoaded ? "show" : "hide"}`}>
        <div className="first-container">
          <img
            src={lock}
            alt="Lock Img"
            className="srcma"
            onLoad={handleImageLoad}
          />
        </div>

        {forgotCard ? (
          <>
            <ForgotComponent
              arg={forgotPasswordState}
              setForgotCard={setForgotCard}
            />
          </>
        ) : (
          <>
            <div className="secondMain">
              <div className="second_container ">
                <img
                  src={mainLogo}
                  alt="Logo"
                  className="LogoMain"
                  onLoad={handleImageLoad}
                />
                {imageLoaded && (
                  <div className="formContainer">
                    <Form
                      name="basic"
                      labelCol={{
                        span: 10,
                      }}
                      wrapperCol={{
                        span: 18,
                      }}
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                    >
                      <Form.Item
                        name="username"
                        rules={[
                          {
                            required: true,
                            message: "* Please enter username or GUID",
                          },
                        ]}
                      >
                        <Input placeholder="User Name or GUID" className="inputCss" />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "* Please enter password",
                          },
                        ]}
                      >
                        <Input.Password
                          type="Password"
                          placeholder="Password"
                          className="inputCss"
                        />
                      </Form.Item>

                      <Form.Item
                        wrapperCol={{
                          offset: 8,
                          span: 20,
                        }}
                      >
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{
                            backgroundColor: "white",
                            color: "#172b4d",
                            borderRadius: 20,
                            marginTop: 10,
                            width: 100,
                            height: 40,
                          }}
                        >
                          Login
                        </Button>
                      </Form.Item>
                    </Form>
                    <div className="Forgotcss">
                      <p onClick={() => ForgotPasswordFunc("Forgot Password")}>
                        Forgot Password?
                      </p>
                      <p
                        onClick={() =>
                          ForgotPasswordFunc("Forgot Instute Code")
                        }
                      >
                        Forgot Institute Code?
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default Login;