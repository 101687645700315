import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import config from "../../Config";

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const getToken = Cookies.get("jwt_Token");
  const loginInfo = location.state || {};

  const roles = loginInfo.roles || [];

  const handleCardClick = (role) => {
    if (getToken) {
      let url;
      switch (role) {
        case "INSTITUTE_ADMIN":
          url = `${config.instAdminUrl}?accessToken=${getToken}&branchCode=${loginInfo.branchCode}&userId=${loginInfo?.entityId}&entity=${loginInfo?.entity}`;
          break;
        case "STAFF":
          url = `${config.staffUrl}?accessToken=${getToken}&branchCode=${loginInfo.branchCode}&userId=${loginInfo?.entityId}&entity=${loginInfo?.entity}`;
          break;
        case "STUDENT":
          url = `${config.studentUrl}?accessToken=${getToken}&branchCode=${loginInfo.branchCode}&userId=${loginInfo?.entityId}&entity=${loginInfo?.entity}`;
          break;
        case "LIBRARIAN":
          url = `${config.libraryUrl}?accessToken=${getToken}&branchCode=${loginInfo.branchCode}}&userId=${loginInfo?.entityId}&entity=${loginInfo?.entity}`;
          break;
        default:
          url = `${config.instAdminUrl}?accessToken=${getToken}&branchCode=${loginInfo.branchCode}&userId=${loginInfo?.entityId}&entity=${loginInfo?.entity}`;
      }
      window.open(url);
    }
  };

  const logoutFunction = () => {
    Cookies.remove("jwt_Token");
    navigate("/");
  };

  return (
    <>
      <div
        className="site-card-border-less-wrapper"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {roles.includes("INSTITUTE_ADMIN") && (
          <Button
            style={{
              margin: "40px",
              width: "250px",
              height: "150px",
              fontSize: "25px",
              fontWeight: "bold",
              backgroundColor: "#172b4d",
              color: "white",
            }}
            onClick={() => handleCardClick("INSTITUTE_ADMIN")}
          >
            Instutute Admin
          </Button>
        )}
        {roles.includes("STAFF") && (
          <Button
            style={{
              margin: "40px",
              width: "250px",
              height: "150px",
              fontSize: "30px",
              fontWeight: "bold",
              backgroundColor: "#172b4d",
              color: "white",
            }}
            onClick={() => handleCardClick("STAFF")}
          >
            Staff
          </Button>
        )}
        {roles.includes("STUDENT") && (
          <Button
            style={{
              margin: "40px",
              width: "250px",
              height: "150px",
              fontSize: "30px",
              fontWeight: "bold",
              backgroundColor: "#172b4d",
              color: "white",
            }}
            onClick={() => handleCardClick("STUDENT")}
          >
            Student
          </Button>
        )}
        {roles.includes("LIBRARIAN") && (
          <Button
            style={{
              margin: "40px",
              width: "250px",
              height: "150px",
              fontSize: "30px",
              fontWeight: "bold",
              backgroundColor: "#172b4d",
              color: "white",
            }}
            onClick={() => handleCardClick("LIBRARIAN")}
          >
            Library
          </Button>
        )}
      </div>
      <div>
        <Button
          type="primary"
          className="submit-button"
          style={{
            marginLeft: "40px",
            width: "100px",
            height: "50px",
            fontSize: "14px",
            backgroundColor: "#172b4d",
            color: "white",
          }}
          onClick={logoutFunction}
        >
          Logout
        </Button>
      </div>
    </>
  );
};

export default Home;
